<template>
    <div class="register">
        <v-row class="w-100 ma-0 register__row">
            <v-col md="5" cols="12" class="pa-0 register__col d-flex align-center">
                <img
                        v-if="!isMobile || (isMobile && $route.fullPath == '/register')"
                        class="register__img object-cover"
                        height="700px"
                        width="100%"
                        :src="imgHandler()"
                        alt=""
                />
            </v-col>
            <v-col md="7" cols="12" class="relative">
                <!-- head  img -->
                <div
                        class="register__head"
                        :class="$route.meta.leftstyle ? 'text-left' : 'text-center'"
                >
                    <img
                            class="cursor-pointer"
                            @click="$router.push('/')"
                            :src="require('@/assets/images/logo.png')"
                            alt=""
                    />
                </div>
                <div class="register__content">
                    <slot/>
                    <router-view></router-view>
                </div>
                <div
                        class="register__footer text-center d-flex justify-center w-100 over-scroll mt-14"
                        v-if="!isMobile"
                >
                    <!-- footer links -->
                    <div class="w-100">
                        <!-- <span
                          class="d-inline-block mx-2 font-16 font-300"
                          v-for="(link, i) in links"
                          :key="i"
                          >{{ link.title }}</span
                        > -->

                        <!-- tag -->
                        <a
                                class="darkprimary--text font-700 font-16 d-block"
                                href="#"
                                target="_blank"
                        >@ Medicaljob</a
                        >
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data: (vm) => ({
        links: [
            {title: vm.$t("heroPage.help"), active: false},
            {title: vm.$t("heroPage.privacy"), active: false},
            {title: vm.$t("heroPage.legal"), active: false},
            {title: vm.$t("heroPage.terms"), active: false},
        ],
    }),
    methods: {
        imgHandler() {
            if (this.$route.path == "/register/signup-clinic") {
                return require(`@/assets/images/register-hospital-bg.jpg`);
            } else {
                return require(`@/assets/images/${
                    this.isMobile ? "register-bg" : "register-bg-web"
                }.png`);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.register {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 700px;

  &__row,
  &__col {
    min-height: 700px;

  }

  &__head {
    margin-top: 13%;
    padding: 0px 20% 0px 20%;
  }

  &__content {
    padding: 0px 20% 0px 20%;
    margin-top: 10px;
  }

  &__img {
    height: 100%;
  }

  &__footer {
    position: relative;
  }

  @media (max-width: 900px) {
    background: $bordercolor !important;
    overflow: auto;
    overflow-x: hidden;
    height: 100vh;
    min-height: unset;
    &__row,
    &__col {
      min-height: unset;

    }
    &__img {
      height: 300px;
      width: auto;
    }
    &__content {
      padding: 0px 20px;
    }
    &__head {
      text-align: center !important;
    }
  }
}
</style>
