<template>
    <div class="country-details">
        <div class="max-width">
            <div class="py-3 white radius-15 mb-14 px-5">
        <span class="font-600 font-32 black--text d-block">
          {{ data.name }} {{ $t('homepage.countryDetailsTitle') }}
        </span>
                <img
                        class="city-img w-100 mt-3 object-cover"
                        :src="data.banner"
                        alt=""
                />
                <v-expansion-panels class="my-6" v-model="panel" multiple>
                    <v-expansion-panel v-for="(item, i) in items" :key="i">
                        <v-expansion-panel-header>
                            <div class="d-flex align-center w-100">
                                <img
                                        class="mr-3"
                                        width="30px"
                                        height="30px"
                                        :src="getImg(item.services.icon)"
                                        alt=""
                                />
                                <span class="font-600 font-18 register-primary--text">{{
                                    item.services.name
                                    }}</span>
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div class="row" v-for="(text, j) in item.sub_services" :key="j">
                                <div class="px-5 py-2 radius-10 col-9">
                                    <span class="font-600 font-18">{{ text.description }}</span>
                                </div>
                                <div
                                        class="px-5 py-2 radius-10 col-3"
                                        style="text-align: center"
                                >
                                    <b class="font-18 register-primary--text">
                                        {{ text.price }}
                                    </b>
                                </div>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>

            <div class="max-width">
                <layout-register class="hero-section">
                    <hero-page></hero-page>
                </layout-register>
            </div>
            <get-in-touch></get-in-touch>
        </div>
    </div>
</template>

<script>
import GetInTouch from "../../../components/core/GetInTouch.vue";
import HeroPage from "@/views/register/hero-page/Index";
import LayoutRegister from "@/views/register/Index";

export default {
    components: {GetInTouch, HeroPage, LayoutRegister},
    data: () => ({
        items: [],
        panel: [0, 1, 2, 3, 4, 5, 6],
        data: "",
    }),
    methods: {
        async getData() {
            let formData = new FormData();

            formData.append("country_id", this.$route.params.id);
            let data = await this.$axios.post("country/details", formData);
            if (data) {
                this.data = data.data.data;
                this.items = this.data.details;
            }
        },
        getImg(url) {
            const result = url.split("backend.").join("");
            console.log("siadadadad");
            console.log(result);
            return result;
        },
    },
    async created() {
        await this.getData();
    },
};
</script>

<style lang="scss">
.country-details {
  .city-img {
    height: 70vh;
    border-radius: 25px;
  }

  .v-expansion-panel {
    background: #e9f2ff !important;
    margin-bottom: 5px;
  }

  .v-expansion-panel-content__wrap {
    div:nth-child(odd) {
      background: #fff !important;
    }
  }

  .v-expansion-panel:before {
    box-shadow: none !important;
  }

  .v-expansion-panel:after {
    border: none !important;
  }

  .hero-section {
    background: #ffffff;
    backdrop-filter: blur(64.5px);
    height: auto !important;
    border-radius: 30px;
    margin-top: 100px;
    margin-bottom: 100px;
    box-shadow: 0px 56px 80px 0px #adc7ee66 !important;
  }
}
</style>
